import React from 'react'
import PaymentCard from '../../components/cards/payment';
import { LiveChatWidget } from '@livechat/widget-react'

const Payment = () => {
  return (
    <div>
        <PaymentCard />
        {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  )
}

export default Payment;